import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { StaticQuery, graphql } from "gatsby"

const Footer = (props) =>{
    return (
        <StaticQuery
        query={graphql`
          query FooterQuery {
            sanityGlobalConfig{
                telefono
                email
                Direccion1
                Direccion2
                logoIcon{
                  asset{
                    url
                  }
                }
                redesSociales{
                  title
                  enlace
                  image{
                    asset{
                      url
                    }
                  }
                }
            }
          }
        `}
        render={data => ( 
        <div class="footer">
            <img class="logoFooter" src={data.sanityGlobalConfig.logoIcon.asset.url} alt="Ícono Inversur Propiedades"/>
            <Container>
                <Row className="justify-content-end">
                    <Col md={5} className="contacto">
                        <div class="tituloFooter">CONTÁCTANOS</div>
                            <p>Teléfono : {data.sanityGlobalConfig.telefono}</p>
                            <p>{data.sanityGlobalConfig.email}</p>
                            <br />
                            <p>{data.sanityGlobalConfig.Direccion1}</p>
                            <p>{data.sanityGlobalConfig.Direccion2}</p>
                    
                    </Col>
                    <Col md={5}>
                        <div class="tituloFooter align-center">SÍGUENOS</div>
                        <div class="redes">
                            {
                                data.sanityGlobalConfig.redesSociales.map(item =>(
                                    <a href={item.enlace}><img class="rrss" src={item.image.asset.url} alt={"Logo "+item.image.title} /></a>
                                ))
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>)}/>
    )
}
export default Footer
import React from "react"
import Navbar from 'react-bootstrap/Navbar'
import Nav from "react-bootstrap/Nav"
import { StaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
const Header = () =>{
    return (
        <StaticQuery
        query={graphql`
          query HeaderQuery {
            sanityGlobalConfig{
                logoDark{
                  asset{
                    url
                  }
                }
            }
          }
        `}
        render={data => (       
            <div>
                <Navbar expand="lg" variant="dark">
                    <Navbar.Brand>
                      
                        <Link to="/"><img className="logo" src={data.sanityGlobalConfig.logoDark.asset.url} alt="Logo inversur"/> </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mx-auto">
                            <Link className="nav-link" to="/comunicados">COMUNICADOS</Link>
                            <Link className="nav-link" to="/buscarPropiedad" >PROPIEDADES</Link>
                            <Link className="nav-link" to="/buscarPropiedad?modalidad=Arriendo">ARRIENDOS</Link>
                            <Link className="nav-link" to="/buscarPropiedad?tipo=Terreno"  >TERRENOS</Link>
                            <Link className="nav-link" to="/publicarPropiedad" >PUBLICAR</Link>
                            <Link className="nav-link" to="/contacto" >CONTÁCTANOS</Link>
                        </Nav>
                    </Navbar.Collapse>
                    </Navbar>
            </div>
         )}
    />)
}
export default Header


import React from "react"
import Header from "./Header"
import Footer from "./Footer"
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/main.css';

const Template=(props)=>{
    return(
        <div>
            <Header/>
            {props.children}
            <Footer/>
        </div>
    )
}

export default Template